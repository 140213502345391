var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:_vm.$style.card},[_c('div',{class:_vm.$style.planFiltersTop},[_c('Filters',{on:{"filter_update":_vm.filter_update}}),_c('div',{class:_vm.$style.planBtnBlock},[_c('v-btn',{class:[_vm.$style.uploadBtn, _vm.$style.cartDownloadBtn, {'pl-0' : _vm.is_not_desktop}],attrs:{"text":"","loading":_vm.is_download_loading,"color":"accent darken-4"},on:{"click":_vm.get_docs}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tray-arrow-down")]),_vm._v(" Скачать отчет ")],1),_c('ClearLocalStorage',{attrs:{"default_cols":_vm.cols_default,"storage_name":_vm.cols_storage_name}})],1)],1),_vm._l((_vm.updated_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),(_vm.is_filter_updated)?_c('Table',{attrs:{"headers":_vm.updated_cols,"items":_vm.sales,"options":_vm.table_options,"custom-sort":_vm.prevent_sort,"loading":_vm.loading,"no-data-text":"Товары, удовлетворяющие выбранным параметрам, отсутствуют","hide-default-footer":""},on:{"update:options":function($event){_vm.table_options=$event}},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.category)+" ")])]}},{key:"item.price_type",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.price_type)+" ")])]}},{key:"item.plan",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.plan)+" ")])]}},{key:"item.fact",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.fact)+" ")])]}},{key:"item.delta",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.delta)+" ")])]}},{key:"item.percent",fn:function(ref){
var _obj;

var item = ref.item;return [_c('td',{staticClass:"py-2",class:( _obj = {}, _obj[_vm.$style.unfulfilled] = parseFloat(item.percent) < _vm.total_percent, _obj[_vm.$style.completed] = parseFloat(item.percent) >= _vm.total_percent, _obj )},[_vm._v(" "+_vm._s(item.percent)+"% ")])]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)}):_vm._e(),_c('div',{staticClass:"mt-6",class:_vm.$style.tableDesc},[_vm._v("* Минимальный порог выполнения плана для сохранение статуса: "+_vm._s(_vm.total_percent)+"%")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }